<template>
  <div>
    <employee-list></employee-list>
  </div>
</template>

<script>

export default {
}
</script>